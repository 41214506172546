import React from "react"
import { Helmet } from "react-helmet"

interface HeadProps {
  lang: string
}

export const Head: React.FC<HeadProps> = ({ lang, children }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="theme-color" content="#fcdbcb" />
      {children}
    </Helmet>
  )
}
